@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  padding: 0 15px;

  @media (--viewportMedium) {
    justify-content: space-between;
    padding: 0;
  }
}

.defaultUserFields {
  margin-top: 10px;
  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.row,
.customFields {
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password { 
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.bottomWrapper {
  margin-top: 35px;
}

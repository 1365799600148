@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  padding: 0 15px;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-top: 0;
    padding: 0;
  }
}

.password {
  margin-top: 15px;
}

.bottomWrapper {
  margin-top: 35px;
  text-align: center;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
    color: #000;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
    color: #000;
}
